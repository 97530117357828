<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Solicitudes Vehiculos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tif</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Operación</li>
                  <li class="breadcrumb-item active">Solicitudes Vehiculos</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2 border-0">
                <div class="row">
                  <div class="col-md-9">
                    <h5 class="mb-0">
                      Formulario {{ accion }} Solicitudes Vehiculos
                    </h5>
                  </div>
                  <div class="col-md-3">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn bg-navy"
                        @click="enviarCorreo()"
                        v-if="
                          $store.getters.can(
                            'tif.solicitudesVehiculos.enviarCorreo'
                          ) && form.estado == 2
                        "
                      >
                        <i class="fas fa-envelope"></i>
                        <br />EnviarCorreo
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="tab-Principal"
                      data-toggle="tab"
                      href="#Principal"
                      >Principal</a
                    >
                  </li>
                  <li class="nav-item" v-if="form.id">
                    <a
                      class="nav-link"
                      id="tab-Detalles"
                      data-toggle="tab"
                      href="#Detalles"
                      @click="$refs.SolicitudesVehiculosDetalles.getOperacion()"
                      >Detalles</a
                    >
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade active show" id="Principal">
                    <div class="card-body">
                      <div class="row">
                        <div class="form-group col-md-4">
                          <label>Nombre</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="form.nombre"
                            :class="
                              $v.form.nombre.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            disabled
                          />
                        </div>
                        <div class="form-group col-md-4">
                          <label>Bloque</label>
                          <v-select
                            :class="[
                              $v.form.bloque_id.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="bloque"
                            placeholder="Bloques"
                            label="nombre"
                            :options="listasForms.bloques"
                            class="form-control form-control-sm p-0"
                            :disabled="form.id && !this.permite_editar"
                            @input="validaSolicitudVehiculoBloque"
                          ></v-select>
                        </div>
                        <div class="form-group col-md-4">
                          <label>Transportadora</label>
                          <v-select
                            :class="[
                              $v.form.empresa_id.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="empresa"
                            placeholder="Empresa"
                            label="razon_social"
                            :options="listasForms.empresas"
                            class="form-control form-control-sm p-0"
                            :disabled="form.id && !this.permite_editar"
                            @input="getSelectEmpresas"
                          ></v-select>
                        </div>
                        <div class="form-group col-md-4">
                          <label for="fecha_inicial"
                            >Fecha y Hora Inicial</label
                          >
                          <div class="row ml-1">
                            <input
                              type="date"
                              v-model="form.fecha_inicial"
                              style="font-size: 13px"
                              class="form-control form-control-sm col-md-6"
                              :min="manana"
                              :class="
                                $v.form.fecha_inicial.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              :disabled="form.id && !this.permite_editar"
                              @input="validaFechasForm"
                            />
                            <input
                              v-model="form.hora_ini"
                              type="text"
                              class="form-control form-control-sm col-md-5 ml-4"
                              style="font-size: 13px"
                              v-mask="{
                                mask: '99:99:99',
                                hourFormat: '23',
                              }"
                              :class="
                                $v.form.hora_ini.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              disabled
                            />
                          </div>
                        </div>
                        <div class="form-group col-md-4">
                          <label for="fecha_final">Fecha y Hora final</label>
                          <div class="row ml-1">
                            <input
                              type="date"
                              v-model="form.fecha_final"
                              :min="manana"
                              style="font-size: 13px"
                              class="form-control form-control-sm col-md-6"
                              :class="
                                $v.form.fecha_final.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              :disabled="form.id && !this.permite_editar"
                              @input="validaFechasForm"
                            />
                            <input
                              v-model="form.hora_fin"
                              type="text"
                              class="form-control form-control-sm col-md-5 ml-4"
                              style="font-size: 13px"
                              v-mask="{
                                mask: '99:99:99',
                                hourFormat: '23',
                              }"
                              :class="
                                $v.form.hora_fin.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              disabled
                            />
                          </div>
                        </div>
                        <div class="form-group col-md-4">
                          <label>Estado</label>
                          <select
                            class="form-control form-control-sm"
                            v-model="form.estado"
                            :class="
                              $v.form.estado.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="
                              !form.id ||
                                !this.permite_editar ||
                                (form.id && this.cant_detalles == 0)
                            "
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="tipo_documento in listasForms.estados"
                              :key="tipo_documento.numeracion"
                              :value="tipo_documento.numeracion"
                            >
                              {{ tipo_documento.descripcion }}
                            </option>
                          </select>
                        </div>
                        <div class="form-group col-md-6">
                          <label>Observación</label>
                          <textarea
                            class="form-control form-control-sm"
                            v-model="form.observacion"
                            :class="
                              $v.form.observacion.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="form.id && !this.permite_editar"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="Detalles">
                    <div class="card-body">
                      <SolicitudesVehiculosDetalles
                        ref="SolicitudesVehiculosDetalles"
                        v-if="form.id != null"
                      ></SolicitudesVehiculosDetalles>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="
                      (!form.id &&
                        !this.permite_editar &&
                        !$v.form.$invalid &&
                        $store.getters.can('tif.solicitudesVehiculos.edit')) ||
                        (form.id &&
                          this.permite_editar &&
                          !$v.form.$invalid &&
                          $store.getters.can('tif.solicitudesVehiculos.edit'))
                    "
                    @click="save()"
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import SolicitudesVehiculosDetalles from "./SolicitudesVehiculosDetalles";
import moment from "moment";

export default {
  name: "SolicitudesVehiculosForm",
  components: {
    Loading,
    vSelect,
    SolicitudesVehiculosDetalles,
  },
  data() {
    return {
      cargando: true,
      moment: moment,
      hoy: moment().format("YYYY-MM-DD"),
      manana: moment()
        .add(1, "days")
        .format("YYYY-MM-DD"),
      solicitud_vehiculos: {},
      permite_editar: true,
      cant_detalles: 0,
      validar: 0,
      bloque: {},
      empresa: {},
      form: {
        nombre: null,
        bloque_id: null,
        empresa_id: null,
        fecha_ini: null,
        fecha_inicial: null,
        hora_ini: null,
        fecha_fin: null,
        fecha_final: null,
        hora_fin: null,
        observacion: null,
        estado: null,
      },
      accion: "",
      metodo: "",
      listasForms: {
        bloques: [],
        empresas: [],
        estados: {},
        tipo_vehiculo: [],
        turnos: [],
        tipo_tarifas: {},
        productos: [],
        rutas: [],
      },
    };
  },
  validations() {
    return {
      form: {
        nombre: {
          required,
        },
        bloque_id: {
          required,
        },
        empresa_id: {
          required,
        },
        fecha_inicial: {
          required,
        },
        hora_ini: {
          required,
        },
        fecha_final: {
          required,
        },
        hora_fin: {
          required,
        },
        observacion: {
          required,
        },
        estado: {
          required,
        },
      },
    };
  },

  methods: {
    getIndex() {
      this.cargando = true;
      this.accion = this.$route.params.accion;
      if (this.$route.params.accion == "Editar") {
        this.metodo = "PUT";
        this.solicitud_vehiculos = this.$route.params.solicitud_vehiculo;
        this.form.id = this.solicitud_vehiculos.id;
        this.form.nombre = this.solicitud_vehiculos.nombre;

        this.bloque = {
          id: this.solicitud_vehiculos.bloque_id,
          nombre: this.solicitud_vehiculos.bloque.nombre,
        };

        this.form.bloque_id = this.bloque.id;
        this.getRutas(this.form.bloque_id);

        this.empresa = {
          id: this.solicitud_vehiculos.empresa_id,
          razon_social: this.solicitud_vehiculos.empresa.razon_social,
        };

        this.form.empresa_id = this.empresa.id;

        let fechai = this.solicitud_vehiculos.fecha_ini.slice(0, 10);
        let horai = this.solicitud_vehiculos.fecha_ini.slice(11, 19);

        this.form.fecha_inicial = fechai;
        this.form.hora_ini = horai;

        let fechaf = this.solicitud_vehiculos.fecha_fin.slice(0, 10);
        let horaf = this.solicitud_vehiculos.fecha_fin.slice(11, 19);

        this.form.fecha_final = fechaf;
        this.form.hora_fin = horaf;

        this.form.observacion = this.solicitud_vehiculos.observacion;
        this.form.estado = this.solicitud_vehiculos.estado;

        this.permite_editar = true;
        if (
          this.solicitud_vehiculos.estado == 2 ||
          this.solicitud_vehiculos.estado == 3
        ) {
          this.permite_editar = false;
        }

        this.cant_detalles = 0;
        if (this.solicitud_vehiculos.tif_det_solicitudes_vehiculos) {
          this.cant_detalles = this.solicitud_vehiculos.tif_det_solicitudes_vehiculos.length;
        }
      } else {
        this.form.hora_ini = "00:00:00";
        this.form.hora_fin = "23:59:59";
        this.metodo = "POST";
        this.form.estado = 1;
        this.permite_editar = false;
      }
      this.cargando = false;
    },

    getEstados() {
      axios.get("/api/lista/154").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getTipoVehiculo() {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 3,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tipo_vehiculo = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getTurnos() {
      const filtros = {
        linea_negocio_id: [3],
        estado: 1,
      };
      axios
        .get("/api/admin/turnos/lista", {
          params: filtros,
        })
        .then((response) => {
          this.listasForms.turnos = response.data;
        });
    },

    getRutas(bloque_id = null) {
      this.listasForms.rutas = [];
      if (bloque_id) {
        const filtros = {
          estado: 1,
          bloque_id,
        };
        axios
          .get("/api/tif/rutas/lista", {
            params: filtros,
          })
          .then((response) => {
            this.listasForms.rutas = response.data;
          });
      }
    },

    getTipoTarifa() {
      axios.get("/api/lista/96").then((response) => {
        this.listasForms.tipo_tarifas = response.data;
      });
    },

    getBloques() {
      axios.get("/api/admin/bloques/lista").then((response) => {
        this.listasForms.bloques = response.data;
      });
    },

    getTransportadoras() {
      axios
        .get("/api/admin/empresas/lista", {
          params: {
            linea_negocio_id: [3],
          },
        })
        .then((response) => {
          this.listasForms.empresas = response.data;
        });
    },

    validaSolicitudVehiculoBloque() {
      this.getSelectBloques();
      this.getRutas(this.form.bloque_id);
      if (this.form.bloque.id) {
        let me = this;
        axios
          .get("/api/tif/solicitudesVehiculos/SolicitudBloque", {
            params: {
              bloque_id: this.form.bloque.id,
            },
          })
          .then((response) => {
            if (response.data.length > 0) {
              const solicitud = response.data[0];
              //   Se pregunta si el estado de la solicitud encontrada esta en borrador
              if (solicitud.estado == 1 && this.form.id != solicitud.id) {
                this.$swal({
                  icon: "error",
                  title: `El bloque ${me.bloque.nombre} tiene una solicitud pendiente por confirmar.`,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                me.bloque = [];
                me.form.bloque_id = null;
                me.getNombre();
              }
            }
          });
      }
    },

    validaFechasForm() {
      if (this.form.fecha_inicial) {
        //this.validarContrato();
        const actual = moment().format("YYYY-MM-DD");
        const fecha_inici = moment(this.form.fecha_inicial).format(
          "YYYY-MM-DD"
        );
        if (actual >= fecha_inici) {
          this.form.fecha_inicial = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicio no puede ser menor a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }

      if (this.form.fecha_inicial && this.form.fecha_final) {
        const fecha_me = new Date(this.form.fecha_inicial).getTime();
        const fecha_ma = new Date(this.form.fecha_final).getTime();
        var diferencia = fecha_ma - fecha_me;
        var fecha_limite = diferencia / (1000 * 60 * 60 * 24);
        if (fecha_limite >= 31) {
          this.form.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `Las fechas no pueden ser mayor a 31 días...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      this.getNombre();
    },

    getNombre() {
      this.form.nombre = null;
      if (
        this.form.bloque_id &&
        this.form.fecha_inicial &&
        this.form.fecha_final
      ) {
        this.form.nombre = `${this.bloque.nombre} ${this.form.fecha_inicial} ${this.form.hora_ini} - ${this.form.fecha_final} ${this.form.hora_fin}`;
      }
    },

    getSelectBloques() {
      this.form.bloque_id = null;
      if (this.bloque) {
        this.form.bloque_id = this.bloque.id;
        this.getNombre();
      }
    },

    getSelectEmpresas() {
      this.form.empresa = {};
      this.form.empresa = null;
      if (this.empresa) {
        this.form.empresa = this.empresa;
        this.form.empresa_id = this.empresa.id;
      }
    },

    /* validarContrato() {
      axios({
        method: "GET",
        url: "/api/tif/solicitudesVehiculos/valContratos",
        params: this.form,
      }).then((response) => {
        this.validar = response.data.estado;
        if (this.validar == 0) {
          this.$swal({
            icon: "error",
            title: `No se puede nominar para esta fecha ya que no se encuentra un contrato vigente...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.bloque = [];
          this.form.bloque_id = null;
          this.form.bloque = {};
          this.form.fecha_inicial = null;
          this.form.fecha_inicial = null;
          this.form.empresa_id = null;
          this.form.empresa = {};
          this.empresa = [];
          this.getNombre();
        }
      });
    }, */

    // Acciones de formularios
    save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        this.form.fecha_ini =
          this.form.fecha_inicial + " " + this.form.hora_ini;
        this.form.fecha_fin = this.form.fecha_final + " " + this.form.hora_fin;
        axios({
          method: this.metodo,
          url: "/api/tif/solicitudesVehiculos",
          data: this.form,
        })
          .then((response) => {
            this.form.id = response.data.id;
            this.solicitud_vehiculos = response.data;
            if (this.$route.params.accion == "Editar") {
              this.$refs.SolicitudesVehiculosDetalles.getSolicitud();
            }
            this.$route.params.accion = "Editar";
            this.$route.params.solicitud_vehiculos = this.form.id;
            this.$route.params.solicitud_vehiculo = response.data;

            this.$swal({
              icon: "success",
              title: "Se actualizó la solicitud correctamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.getIndex();
            this.cargando = false;
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    back() {
      return this.$router.replace("/tif/solicitudesVehiculos");
    },

    enviarCorreo() {
      axios
        .get("/api/tif/solicitudesVehiculos/enviarCorreo", {
          params: {
            solicitud_vehiculo: this.$route.params.solicitud_vehiculo.id,
            empresa_id: this.solicitud_vehiculos.empresa_id,
          },
        })
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "Correo enviado satisfactoriamente",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Error, en el envio de correo:" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
  },

  async mounted() {
    this.getIndex();
    this.getEstados();
    this.getTipoVehiculo();
    this.getTurnos();
    this.getBloques();
    this.getTransportadoras();
    this.getTipoTarifa();
  },
};
</script>
